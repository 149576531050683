import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PublicIcon from '@material-ui/icons/Public';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { deleteNotice, changePublishStatus } from './noticeService';
import { toast } from 'react-toastify';
import hide from  '../Assets/icons/plain.jpg'
import publish from  '../Assets/icons/plain.jpg'
import BadgeAvatar from './Avatar'
import moment from 'moment'
import swal from 'sweetalert2'


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
   
  },
  media: {
    height: 0,
    paddingTop: '12.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props, context) {
  const classes = useStyles();
  const { notice, source } = props;
  const [expanded, setExpanded] = React.useState(false);
  const [pub, setPub] = React.useState(!notice.publish);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

 const handleDelete = async(notice_id) => {
  
  swal.fire({
    title: 'Do you want to delete it?',
    showCancelButton: true,
    confirmButtonText: `OK`,
    confirmButtonColor: 'red',
    icon: 'warning',

  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      await deleteNotice(notice_id);
      props.parentCallback1(notice_id);
     // window.location.reload();
    } else if (result.isDenied) {
      swal.fire('Sorry Action not possible', '', 'info')
    }
  })
  

  
  }

  const getButtonText = isPublished => {
    return isPublished ? <span className="badge badge-primary"><PublicIcon/></span> : <span className="badge badge-secondary"><VisibilityOffIcon/></span>
  }

//   getButtonColor = isPublished => {
//     return isPublished ? "btn btn-primary" : "btn btn-warning"
//   }

//   const getPublishText = status_bool => {
//     return status_bool ? "Published" : "Hidden"
//   }

 const handlePublishChange = async(notice_id, flag) => {
    try{
      await changePublishStatus(notice_id, flag);
      await setPub(!flag);
      

      // props.parentCallback2(notice_id, flag);
    

      //window.location.reload()
    }catch(error){
      toast.error("Update failed")
    }
  }

  const clickEdit = () =>{
    console.log("kk",source)
   props.parentCallback({notice}, source);


  }

 //var en = notice.body.match(/(?<=<<en>>\s*).*?(?=\s*<<ru>>)/gs)
  var en =  notice.body.split('<<en>>')[1].split('<<ru>>')[0]
 var ru = notice.body.split('<<ru>>')[1].split('<<ko>>')[0]
 var ko =  notice.body.split('<<ko>>')[1].split('<<ja>>')[0]
 var ja =  notice.body.substring(notice.body.indexOf('<<ja>>') + 6);
 var res = notice.title.substr(0,1);
 var momz = moment(notice.timestamp).startOf('seconds').fromNow();
  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <BadgeAvatar data={res} publish={!pub}/>
        }
      
        title={<b style={{'text-transform': 'uppercase'}}>{notice.title}</b>}
        subheader={momz}
      />

         <CardMedia
        className={classes.media}
        image={notice.publish===false?hide:publish}
        title="Paella dish"
      />
     
      <CardContent style={{ 'height': 70}}>
        <Typography variant="body2" color="textSecondary" component="p">
        <b style={{'text-transform': 'uppercase'}}>{notice.summary}</b> 
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
      <Tooltip title={!pub?'De-activate':'Activate'}>
        <IconButton aria-label="add to favorites"  onClick={() => {handlePublishChange(notice.notice_id, pub)}}>
        {getButtonText(!pub)}
        
        </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
        <IconButton aria-label="share"  onClick={() => {handleDelete(notice.notice_id)}}>
         <span className="badge badge-danger"><DeleteForeverIcon/></span> 
        </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton aria-label="settings" onClick ={()=>clickEdit()}>
          <span className="badge badge-warning"><EditOutlinedIcon /></span>  
          </IconButton>
          </Tooltip>
        <Tooltip title="Show More">
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph><span className="badge badge-success">English: </span></Typography>
          <Typography paragraph>
          {en}
          </Typography>

          <Typography paragraph><span className="badge badge-danger">Russian: </span></Typography>
          <Typography paragraph>
          {ru}
          </Typography>

          <Typography paragraph> <span className="badge badge-dark">Korean:</span></Typography>
          <Typography paragraph>
          {ko}
          </Typography>

          <Typography paragraph><span className="badge badge-primary">Japanese:</span></Typography>
          <Typography paragraph>
          {ja}
          </Typography>

        </CardContent>
      </Collapse>
    </Card>
  );
}
