import React, { useState } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GOOGLE_SITE_KEY, isDev } from './config'
import Login from './components/Login';
import Home from './components/Home';
import auth from './services/loginService';
import KycDetails from './components/KycDetails'
import Notices from './components/Notices'
import CreateNotice from './components/CreateNotice'
import CreatePromotions from './components/CreatePromotions'
import SupportTicket from './components/SupportTicket'
import SupportTicketDetails from './components/SupportTicketDetails'
import RestoreVCRequests from './components/RestoreVCRequests'

  if (!isDev) {
    console.log = () => {}
    console.info = () => {}
    console.warn = () => {}
  }

const ProtectedRoute = ({ status, path, component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log(status)
        if (!status)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          );
        return Component ? <Component {...props} idToken={status} /> : render(props);
      }}
    />
  );
};

function App() {
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  // FIX-ME: this will call the function on each rerender
  // Need urgent optimization
  auth.getCurrentUser()
    .then(data => {
      setIsAuthenticated(data?.idToken?.jwtToken ?? false)
      setLoading(false)
      console.log(data)
      return data;
    })
    .catch(err => 
      {console.log(err);
      setIsAuthenticated(false)
      setLoading(false)
    })
  
  // async function isAuth(){
  //   const auth = await auth.getCurrentUser();
  //   this.setState
  // }

  if(loading) {
    console.log('Loading')
    return <p style={{color: 'red'}}>Loading</p>
  }
  // debugger
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_SITE_KEY}>
      <BrowserRouter>
        <main className="container">
          <ToastContainer />
          <Switch>
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/" exact component={Home} status={isAuthenticated}/>   
            <ProtectedRoute path="/home" exact component={Home} status={isAuthenticated}/> 
            <ProtectedRoute path="/kyc_details" exact component={KycDetails} status={isAuthenticated}/>
            <ProtectedRoute path="/notices" exact component={Notices} status={isAuthenticated}/>
            <ProtectedRoute path="/create_notice" exact component={CreateNotice} status={isAuthenticated}/>
            <ProtectedRoute path="/create_promotions" exact component={CreatePromotions} status={isAuthenticated}/>
            <ProtectedRoute path="/support_ticket" exact component={SupportTicket} status={isAuthenticated}/>
            <ProtectedRoute path="/support_ticket_detail" exact component={SupportTicketDetails} status={isAuthenticated} />
            <ProtectedRoute path="/restore_vc_request" exact component={RestoreVCRequests} status={isAuthenticated} />
            <Redirect to="/404"/>
          </Switch>
        </main>
    </BrowserRouter>
  </GoogleReCaptchaProvider>
  );
}

export default App;
