var AWS = require("aws-sdk");
const awsConfig = require("../config").AWS_DB_CONFIG()


AWS.config.update({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
    region: awsConfig.region
});

var docClient = new AWS.DynamoDB.DocumentClient();
const table = "support_ticket"

export function getSupportTicketList() {
    return new Promise((resolve, reject) => {
        var params = {
            TableName: table
        };
        docClient.scan(params, async function(err, data) {
            if (err) {
                console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
                reject(JSON.stringify(err, null, 2))
            } else {
                //console.log(data)
                if(data["Items"].length){
                    resolve(data['Items'])
                } else{
                    resolve([])
                }
            }
        });
    });
}

export function updateTicketStatus(ticket_id, status){
    return new Promise((resolve, reject) => {
            var params = {
                TableName:table,
                Key:{
                    "ticket_id": ticket_id
                },
                UpdateExpression: "set #status=:status",
                ExpressionAttributeValues:{
                    ":status":status
                },
                ExpressionAttributeNames:{
                    "#status":"status"
                },
                ReturnValues:"UPDATED_NEW"
            };
            docClient.update(params, async function(err, data) {
                if (err) {
                    console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
                    reject(JSON.stringify(err, null, 2))
                } else {
                    resolve(JSON.stringify(data, null, 2))
                }
            });
        
    });
}