const AWS_ACCOUNT = require("../config").AWS_DB_CONFIG()
//const request = require('request');
var AWS = require("aws-sdk");
AWS.config.update({
    accessKeyId: AWS_ACCOUNT.accessKeyId,
    secretAccessKey: AWS_ACCOUNT.secretAccessKey,
    region: AWS_ACCOUNT.region
});

var db = new AWS.DynamoDB();
var docClient = new AWS.DynamoDB.DocumentClient();
var table = 'notices';

export async function getNoticeService() {
    //console.log('Fetching flagged orders from DB');
    const params = {
      TableName: table
    };
    return new Promise((resolve, reject) => {
      db.scan(params, handleScan);
  
      function handleScan(err, data) {
        if (err) {
          console.log('Error encountered!!');
          reject(err);
        } else {
          console.log('Scan success!');
  
          // continue scanning if we have more items
          if (typeof data.LastEvaluatedKey != 'undefined') {
            console.log('Scanning...');
            params.ExclusiveStartKey = data.LastEvaluatedKey;
            db.scan(params, handleScan);
          } else {
            resolve(convertFromDynamoDBFormat(data));
          }
        }
      }
    });
  }

export async function changePublishStatus(notice_id, publishStatus) {
  console.log(notice_id, publishStatus)
  return new Promise((resolve,reject) => {
      var params = {
          TableName:table,
          Key:{
              "notice_id": notice_id
          },
          UpdateExpression: "set publish = :status",
          ExpressionAttributeValues:{
              ":status": publishStatus
          },
          ReturnValues:"UPDATED_NEW"
      };

      console.log("Updating the item...");
      docClient.update(params, function(err, data) {
      if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
          reject(err)
      } else {
          console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
          resolve(true)
      }
  });
  })
}


  function convertFromDynamoDBFormat(noticeList) {
    const cleanNoticeList = [];
    try {
        noticeList.Items.forEach(function(itemData) {
            cleanNoticeList.push({
            body: itemData.body.S,
            notice_id: itemData.notice_id.S,
            summary: itemData.summary.S,
            timestamp: itemData.timestamp.S,
            title : itemData.title.S,
            publish : itemData.publish.BOOL,
            content_type : itemData.content_type.S
          });
      });
      const notice_list_time_sorted = cleanNoticeList.sort(function(a,b){return b.timestamp>a.timestamp;})
      return notice_list_time_sorted;
    } catch (error) {
      console.log(error);
      return cleanNoticeList;
    }
  }


  /**
 * The function will update the delete the notice from the db
 * @param {Obj} order
 */
export function deleteNotice(notice_id) {
  //setup the params to update as per dynamoDb format
  const params = {
    TableName: table,
    Key: {
      notice_id: notice_id
    }
  };

  return new Promise((resolve, reject) => {
    docClient.delete(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        console.log('Item delete success');
        resolve(true);
      }
    });
  });
}

  /**
 * The function will add a new notice to the DB
 * @param {Obj} order
 */
export function createNotice(title, summary, body, destination) {
  const notice_id = Math.random().toString(36).substring(7);
  const timestamp = new Date().toISOString();
  //setup the params to update as per dynamoDb format
  const params = {
    TableName: table,
    Item: {
      notice_id: notice_id,
      timestamp: timestamp,
      body : body,
      title : title,
      summary : summary,
      publish : false,
      content_type : destination
    }
  };

  console.log(params)

  return new Promise((resolve, reject) => {
    docClient.put(params, function(err, data) {
      if (err) {
        reject(err);
      } else {
        console.log('Item add success');
        resolve(true);
      }
    });
  });
}

export async function updateBody(notice_id,title,summary, body) {
  return new Promise((resolve,reject) => {
    console.log(notice_id)
    console.log(body)

      var params = {
          TableName:table,
          Key:{
              "notice_id": notice_id
          },
          UpdateExpression: "set title = :title, body = :body, summary = :summary",
          ExpressionAttributeValues:{
              ":body" : body,
              ":title" : title,
              ":summary" : summary,
          },
          ReturnValues:"UPDATED_NEW"
      };

      console.log("Updating the item...");
      docClient.update(params, function(err, data) {
      if (err) {
          console.error("Unable to update item. Error JSON:", JSON.stringify(err, null, 2));
          reject(err)
      } else {
          console.log("UpdateItem succeeded:", JSON.stringify(data, null, 2));
          resolve(true)
      }
  });
  })
}