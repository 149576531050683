import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import auth from "../services/loginService";

const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

class SideMenu extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };
  
  render() {
    const { classes } = this.props;
    const sideList = (
      <div className={classes.list}>
      <List>
      <ListItem button
           onClick={() => 
            this.props.history.push('/')}>
          KYC Dashboard
          </ListItem>

          <Divider />

          <ListItem button
            onClick={() => this.props.history.push('/notices')}>
            Notices & Promotions
            </ListItem>
          <Divider />
         

          <ListItem button
            onClick={() => this.props.history.push('/support_ticket')}>
            Support Tickets
            </ListItem>
          <Divider />
          <ListItem button
            onClick={() => this.props.history.push('/restore_vc_request')}>
            Restore VC
            </ListItem>
          <Divider />
          <ListItem button
            onClick={() => {
              auth.logout();
              window.location = "/login";
            }}>
            Logout
            </ListItem>
          <Divider />
        </List>

      </div>

    );

    const fullList = (
      <div className={classes.fullList}>

        <List>
            <ListItem button
           onClick={() => {
            window.location = "/";
          }}>
          KYC Dashboard
          </ListItem>

          <Divider />

          <ListItem button
            onClick={() => {
              window.location = "/notices";
            }}>
            Notices
            </ListItem>
          <Divider />

          <ListItem button
            onClick={() => {
              window.location = "/support_ticket";
            }}>
            Support Tickets
            </ListItem>
          <Divider />

          <ListItem button
            onClick={() => {
              auth.logout();
              window.location = "/login";
            }}>
            Logout
            </ListItem>
          <Divider />
        </List>

      </div>
    );

    return (
      <div>
        <Button style={{backgroundColor:'#1ABC9C'}} onClick={this.toggleDrawer('left', true)}>
        <img src={require('../Assets/icons/menu-icon.png')} alt="sidemenu"/>
        </Button>
         <SwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor="top"
          open={this.state.top}
          onClose={this.toggleDrawer('top', false)}
          onOpen={this.toggleDrawer('top', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('top', false)}
            onKeyDown={this.toggleDrawer('top', false)}
          >
            {fullList}
          </div>
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor="bottom"
          open={this.state.bottom}
          onClose={this.toggleDrawer('bottom', false)}
          onOpen={this.toggleDrawer('bottom', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('bottom', false)}
            onKeyDown={this.toggleDrawer('bottom', false)}
          >
            {fullList}
          </div>
        </SwipeableDrawer>
        <SwipeableDrawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

SideMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SideMenu));