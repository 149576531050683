import React, { Component } from 'react';
import SideMenu from "./SideMenu";
import { isDev } from "../config"
import { toast } from "react-toastify";
import Loader from 'react-loader-spinner';
import { getSupportTicketList } from "../services/supportService"
import SupportTicketTable from "./Assets/SupportTicketTable"
class SupportTicket extends Component {
    state = { 
        ticketList : [],
        showLoader : true
     }

     async componentDidMount() {
      try {
          let ticketList = await getSupportTicketList();
          console.log(ticketList);
          this.setState({ ticketList, showLoader: false });
        } catch (err) {
          console.log(err);
          toast.warning('Cannot fetch data');
          this.setState({
            showLoader: false
          });
        }
      }

    render() { 
        return ( 
            <React.Fragment>
            <div className="jumbotron">
            <div className="container">
            <SideMenu />
            <br />
            <p style={{color:"red"}}>{(isDev ? "DEVELOPMENT | TESTNET" : "PRODUCTION | MAINNET")}</p>
            <h2 className="display-4">Support Tickets </h2>
            {this.state && this.state.showLoader && (
            <div className="col-md-3">
                <p>Loading Data..</p>
                <Loader type="ThreeDots" color="#3f51b5" />
            </div>
            )}
            <p>
                <button className="btn btn-secondary"
                        onClick={() => {
                            window.location = "/";
                        }}>
                        Back
                        </button>
            </p>
            </div>
            </div>
            {this.state && !this.state.showLoader && (
                <div className="col-md-10">
                  {this.state.ticketList.map(ticket => (
                    <ul key={ticket.ticket_id}>
                      <SupportTicketTable ticket={ticket} />
                    </ul>
                  ))}
                </div>
              )}
            
            </React.Fragment>
            );
    }
}
 
export default SupportTicket;