import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class SupportTicketTable extends Component {
    state = {  }

    showDetailPage = ticket => {
        this.props.history.push({
          pathname: '/support_ticket_detail',
          state: { ticket: ticket }
        });
    };

    render() { 
        const { ticket } = this.props;
        return ( 
            <React.Fragment>
        <li>Ticket-ID : <strong>{ticket.ticket_id}</strong></li>
        <li>Category : <strong>{ticket.category}</strong></li>
        <li>Subject : <strong>{ticket.subject}</strong></li>
        <li>Status : <strong>{ticket.status}</strong></li>
        <li><button
            type="button"
            className="btn btn-primary"
            onClick={() => this.showDetailPage(ticket)}
          >
            View
          </button>
        </li>
        <hr />
            </React.Fragment>
        );
    }
}
 
export default withRouter(SupportTicketTable);