import React, { Component } from 'react';
import SideMenu from "./SideMenu";
import { isDev } from "../config"
import { toast } from "react-toastify";
import Loader from 'react-loader-spinner';
import { getNoticeService } from "../services/noticeService"
import RecipeReviewCard from '../services/Card'
import Grid from '@material-ui/core/Grid';

class Notices extends Component {
    state = { 
        showLoader : true,
        noticeList : false,
        promoList : false,
        network: '',
        test: false
     }

    async componentDidMount() {
      console.log("incoming", this.props.location.state)
      this.setState({network:this.props.location.state})
      if(this.props.location.state === undefined){
        this.setState({network:'notice'})

      }
        try {
            let noticeList = await getNoticeService();
            console.log(noticeList);
            console.log("not",noticeList)
            
            let noticeArray = [];
            let promoArray = [];

           await noticeList.forEach(async e=>{
              if(e.content_type === 'noticepage'){
                  noticeArray.push(e)
                 await noticeArray.sort(function(a, b) {
                    var c = new Date(a.timestamp);
                    var d = new Date(b.timestamp);
                    return d-c;
                  })
              }else{
                promoArray.push(e)
                await promoArray.sort(function(a, b) {
                  var c = new Date(a.timestamp);
                  var d = new Date(b.timestamp);
                  return d-c;
                })

              }
            })
            this.setState({ noticeList : noticeArray, promoList: promoArray, showLoader: false });
          } catch (err) {
            console.log(err);
            toast.warning('Cannot fetch data');
            this.setState({
              showLoader: false
            });
          }
    }

    handleChange = () => {
      if(this.state.network === 'notice'){
        this.setState({network: 'promo' });
        
      }
      if(this.state.network === 'promo'){
        this.setState({network: 'notice' });
        
      }
};

callbackFunction = (childData, source) => {
  console.log("ggg", source)
  if(source === 'promo'){
    this.props.history.push("/create_promotions" ,childData);
    return;
    
  }
   this.props.history.push("/create_notice" ,childData);

};

callbackFunction1 = (childData) => {
  this.setState({noticeList:this.state.noticeList.filter(e=>{return e.notice_id !== childData;})
  })
};
callbackFunction2 = (childData) => {
  this.setState({promoList:this.state.promoList.filter(e=>{return e.notice_id !== childData;})
  })
};

callbackFunction3 = (id,flag) => {
  console.log(this.state.noticeList)
  // this.setState(prevState => ({noticeList:prevState.noticeList.map(e=>{if(e.notice_id === id){
  //    e.publish = false
     
  //   }})
//})
  //)
}

    render() { 
        return ( 
            <React.Fragment>
            <div className="jumbotron">
            <div className="container">
            <SideMenu />
            <br />
            <p style={{color:"red"}}>{(isDev ? "DEVELOPMENT | TESTNET" : "PRODUCTION | MAINNET")}</p>
        <h2 className="display-4">{this.state.network === 'notice'?'NOTICES':"PROMOTIONS"}</h2>
        <br></br>
               
          <form>
            <input type="radio" name="fruit" value="swn" onChange={this.handleChange} defaultChecked={this.props.location.state==='notice' || this.props.location.state === undefined} /> <b>NOTICES</b>
            <p></p>
            <input type="radio" name="fruit" value="exchange" onChange={this.handleChange} defaultChecked={this.props.location.state==='promo'}/><b> PROMOTIONS</b>
            
          </form>
            {this.state && this.state.showLoader && (
            <div className="col-md-3">
                <p>Loading Data..</p>
                <Loader type="ThreeDots" color="#3f51b5" />
            </div>
            )}
            <br></br>
            <p>
                <button className="btn btn-primary"
                        onClick={() => {
                          if(this.state.network === 'notice'){
                            this.props.history.push("/create_notice");
                            
                          }
                          if(this.state.network === 'promo'){
                            this.props.history.push("/create_promotions");

                          }
                          

                           // window.location = "/create_notice";
                        }}>
                        {this.state.network==='notice'?'Create Notice':'Create Promo'}
                        </button>
            </p>
            </div>
            </div>
             {/* {this.state && !this.state.showLoader && this.state.network==='notice'&& (
                <div className="col-md-10">
                  {this.state.noticeList.map(order => (
                    <ul key={order.notice_id}>
                      <NoticeTable notice={order} />
                    </ul>
                  ))}
                </div>
              )} */}

            {/* {this.state && !this.state.showLoader && this.state.network==='promo'&& (
                            <div className="col-md-10">
                              {this.state.promoList.map(order => (
                                <ul key={order.notice_id}>
                                  <NoticeTable notice={order} />
                                </ul>
                              ))}
                            </div>
                          )}  */}

                          
      {this.state && !this.state.showLoader && this.state.network==='notice'&& (
                             
                                 <Grid container spacing={3}>
                                {this.state.noticeList.map(order => (
                                  
                                    <Grid item xs={6} sm={4}>
                                      <RecipeReviewCard source='notice' notice={order}  parentCallback={this.callbackFunction}  parentCallback1={this.callbackFunction1} parentCallback2={this.callbackFunction3}/>
                                    </Grid>
                                  
                                ))}
                                </Grid>
                             
                            )}


{this.state && !this.state.showLoader && this.state.network==='promo'&& (
                             
                             <Grid container spacing={3}>
                            {this.state.promoList.map(order => (
                              
                                <Grid item xs={6} sm={4}>
                                  <RecipeReviewCard source='promo' notice={order}  parentCallback={this.callbackFunction} parentCallback1={this.callbackFunction2}/>
                                </Grid>
                              
                            ))}
                            </Grid>
                         
                        )}

                          

                           
                          
            
            </React.Fragment>
            );
    }
}
 
export default Notices;