import React, { Component } from 'react';
import { login, confirmOtp , getPref} from "../services/loginService.js";
import { toast } from "react-toastify";
import { isDev } from "../config.js";
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Grid } from "@material-ui/core";



class Login extends Component {

  state = { 
    account: { email: "", password: "" },
    otpScreen : false,
    selectScreen: false,
    otp : "",
    userObject : {},
    token : ""
  }
  
  // const { executeRecaptcha } = useGoogleReCaptcha();

  handleSubmit = e => {
    e.preventDefault();
    // console.log("Submitted");
    this.doSubmit();
  };

  handleOtpSubmit = e => {
    e.preventDefault();
    // console.log("Submitted");
    this.doOtpSubmit();
  };

  doOtpSubmit = async() => {
    //console.log(this.state.userObject)
    confirmOtp(this.state.userObject, this.state.otp)
    .then(function(response){
        toast.success("Login Success");
        window.location = "/home";
    })
    .catch(err => {
      //console.log(err);
      toast.error(String(err));
      toast.warning("Login failed");
    });
  }

  doSubmit = async () => {
    const { email, password } = this.state.account;
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('login');
    //console.log(token);
    login(email, password, token).then(user => {
    
      if(user.challengeName === 'SELECT_MFA_TYPE'){
        this.setState({userObject : user, selectScreen: true});
        return;

      }
       
      this.setState({userObject : user, otpScreen : true})
    }).catch(err => {
      console.log(err)
      toast.error("Login Failed")
    });
  };
  
  handleChange = ({ currentTarget: input }) => {
    const account = { ...this.state.account };
    account[input.name] = input.value;
    this.setState({ account });
  };

  async handleClick(e) {
  
    if(e === 'sms'){
      let smsSend = await getPref(this.state.userObject,'SMS_MFA')
      if(smsSend === true){
        this.setState({selectScreen:false,otpScreen:true})
      }
      return;
    }
    else{
      let smsSend = await getPref(this.state.userObject,'SOFTWARE_TOKEN_MFA')
      if(smsSend === true){
        this.setState({selectScreen:false,otpScreen:true})
      }
      return;
    }
  }
  
    render() { 
        const { account, otp } = this.state;
        return (
            <div className="col-md-6">
              <br /><br /><br /><br />
              <h2>Metablock KYC Admin Tool</h2>

              {/* <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_SITE_KEY}>
                  <GoogleReCaptcha onVerify={token => this.setState({ token })} />
              </GoogleReCaptchaProvider> */}

              <p className="bold">{isDev ? <strong>DEV Config</strong> : <strong>PROD config</strong>}</p>
              {this.state && !this.state.selectScreen && !this.state.otpScreen && <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="user-email">Email</label>
                  <input
                    value={account.email}
                    onChange={this.handleChange}
                    name="email"
                    id="user-email"
                    type="email"
                    className="form-control"
                    required
                    autoFocus
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="user-password">Password</label>
                  <input
                    value={account.password}
                    onChange={this.handleChange}
                    name="password"
                    id="user-password"
                    type="password"
                    className="form-control"
                    required
                  />
                  <br />
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>}
              

              {this.state && this.state.otpScreen && <form onSubmit={this.handleOtpSubmit}>
                <div className="form-group">
                  <label htmlFor="user-password">OTP</label>
                  <input
                    value={otp}
                    onChange={e => this.setState({ otp: e.target.value })}
                    name="password"
                    id="user-password"
                    type="text"
                    className="form-control"
                    required
                  />
                  <br />
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>} 


              {this.state && this.state.selectScreen && 
                <div className="form-group">
                
              <h4>Select Your OTP Method</h4>
              
                  <Grid
                    
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                
                    <Grid 
                      className="btn-logout-wrap"
                    
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Button
                        className="btn-logout"
                        variant="outlined"
                        color="primary"
                        onClick={() => this.handleClick('sms')}
                      >
                        SMS
                      </Button>
                      <br></br><br></br>
                      <Button
                        className="btn-back"
                        variant="outlined"
                        color="primary"
                        onClick={() => this.handleClick('google')}
                      >
                        GOOGLE
                      </Button>
                    </Grid>
                  </Grid>
              
              </div>
                      }

      </div>
          );;
    }
}
 
export default (withGoogleReCaptcha)(Login);