//aws login code
import { Auth } from "aws-amplify";
import {PERMITTED_USER_GROUP} from "../config"
const awsConfig = require("../config").AWS_COGNITO_CONFIG()

// init amplify with config
Auth.configure(awsConfig);

export async function login(email, password, token) {
  const validationData = {
    'custom:captcha_token': token
  }
  //console.log(validationData)
  return Auth.signIn(email, password, validationData).then(response => {
    //console.log(response);
    
   
    return response;
  }).catch(e => { console.log(e); return e;})
}


export async function getPref(user,prefer){
 
    const cognitoUser = !user ? user.data : user
    cognitoUser.sendMFASelectionAnswer(prefer)
  
    return true;
}


export function logout() {
    Auth.signOut();
  }
  
export function getCurrentUser() { 
  return new Promise(async (resolve, reject) => {
    try 
    {
      const isAuth = await Auth.currentSession();
      resolve(isAuth)
    }
    catch(error){
    reject(false)
    }
  })
}




export function confirmOtp(user, code){
    return new Promise(async(resolve, reject) => {
   
      Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA").then(response => {
        const payload = response.signInUserSession.idToken.payload;
        
        //reject if the user is not in any groups
        if(!("cognito:groups" in payload)){
          throw new Error("User not KYC admin!");
        }

        // reject if user is not in kyc admin group
        if(!(payload['cognito:groups'].includes(PERMITTED_USER_GROUP))){
          throw new Error("User not KYC admin!");
        }
        
        resolve(true);
      
      }).catch(e => { 
          console.log(e); 
          Auth.confirmSignIn(user, code, "SMS_MFA").then(response => {
           
            const payload = response.signInUserSession.idToken.payload;
            
            //reject if the user is not in any groups
            if(!("cognito:groups" in payload)){
              throw new Error("User not KYC admin!");
            }
    
            // reject if user is not in kyc admin group
            if(!(payload['cognito:groups'].includes(PERMITTED_USER_GROUP))){
              throw new Error("User not KYC admin!");
            }
            
            resolve(true);
          
          }).catch(e => { 
              console.log(e)
              reject(e)
          })
      })
    }); 
}

export function getUserToken(){
  return Auth.currentSession(credentials => {
    return credentials;
  })
}
  

  export default {
    login,
    logout,
    getCurrentUser,
    getUserToken,
    getPref
};