import React, { Component } from 'react';
import { getPresignedURL } from "../services/apiService"
import { toast } from 'react-toastify';
import {SUPPORT_TICKET_BUCKET_NAME, SUPPORT_TICKET_STATUS} from "../config"
import {updateTicketStatus} from "../services/supportService"

class SupportTicketDetails extends Component {
    state = { 
        ticket : {},
        image_url : ""
     }

    async componentDidMount() {
        if (this.props.location.state && this.props.location && this.props.location.state.ticket) {
          console.log('Received Prop Data');
          console.log(this.props.location.state.ticket);
          this.setState({ticket : this.props.location.state.ticket});
          if (this.props.location.state.ticket.image){
            const doc_image_url = await this.preparePresignedUrl(this.props.location.state.ticket.image);
            this.setState({image_url : doc_image_url})
          }
        }
    }

    updateStatus = async(ticket_id, status) => {
        await updateTicketStatus(ticket_id, status);
        toast.success('Status Updated!');
        window.location = "/support_ticket";
       }


    preparePresignedUrl = path => {
        let link_path = String(path).split('.com/')[1]
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getPresignedURL(link_path, SUPPORT_TICKET_BUCKET_NAME)
                resolve(res['message'])
            }catch(error){
                console.log(error)
                toast.error("Error!")
                reject(error)
            }
        })
    }

    render() {
        const { ticket } = this.state;
        return ( 
            <React.Fragment>
            <br /><br />
            
            <div className="jumbotron">
            <div className="container">
            <h2 className="display-4">Ticket Details </h2>
            <p>
                <button className="btn btn-info"
                        onClick={() => {
                            window.location = "/support_ticket";
                        }}>
                        Back
                        </button>
            </p>
            </div>
            </div>
            <div className="container">

            <div className="row">
                    <div className="col-md-4">
                        <button type="button" className="btn btn-warning" onClick={() => this.updateStatus(ticket.ticket_id, SUPPORT_TICKET_STATUS.review)}>Set status to In Review</button>
                        <hr/>
                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn btn-primary" onClick={() => this.updateStatus(ticket.ticket_id, SUPPORT_TICKET_STATUS.close)}>Close Ticket</button>
                        <hr/>
                    </div>
            </div> 



            <div className="row">
                <div className="col-md-6">
                            <p>Basic Details : 
                            <hr />
                                
                                Ticket Id : <strong>{ticket.ticket_id}</strong>
                                <br/>
                                User Id  : <strong>{ticket.uid}</strong>
                                <br/><br/>
                                Contact : <strong>{ticket.additionalContact}</strong>
                                <br/>
                            </p>
                            <hr />
                            
                </div>

                <div className="col-md-6">
                        <p>Ticket Details : 
                        <hr />

                            Status : <strong>{ticket.status}</strong>
                            <br />
                            Category : <strong>{ticket.category}</strong>
                            <br />
                            
                            Parent Ticket : <strong>{ticket.parent_ticket_id}</strong>
                            <br/><br/>
                            
                        </p>
                        <hr />
                    </div>
            </div>
            
            <div className="row">
                <div className="col-md-8">
                                <p>Details : 
                                <hr />
                                    Subject : <strong>{ticket.subject}</strong>
                                    <br/><br/>
                                    Description  : <strong>{ticket.description}</strong>
                                    <br/><br/>
                                    
                                </p>
                                <hr />
                                
                </div>
            </div>

            <div className="row">
                    <div className="col-md-6">
                        <p>Attached Image : </p>
                        <img src={this.state.image_url} className="rounded float-left" alt="Nothing attached" width="350" height="350"/>
                    </div>
            </div>


            </div>
            </React.Fragment>)
    }
}
 
export default SupportTicketDetails;