import React, { Component } from 'react';
import { toast } from "react-toastify";
import auth from "../services/loginService";
import Loader from 'react-loader-spinner';
import { isDev, KYC_STATUS_STRING } from "../config"
import { getKYCUsers } from "../services/kycService"
//import { getUserToken } from "../services/loginService"
import SideMenu from "./SideMenu";
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import ComputerIcon from '@material-ui/icons/Computer';
import { MDBDataTable } from 'mdbreact';

import { Button } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';
import { Container, Row, Col } from 'reactstrap';
import moment from 'moment';



class Home extends Component {
    state = { 
        showLoader : true,
        selected_kyc_status : 'S',
        userListLength : false,
        network : 'exchange',
        selected_button : 'S',
        R_Button: false,
        S_Button: true,
        D_Button: false,
        A_Button: false,
        AcceptedCount : '',
        intervalTimer: false,
     }

     getUserList = async(kyc_type, network) => {
      try {
        this.setState({showLoader : true})
        const users = await getKYCUsers(kyc_type);
        this.setState({AcceptedCount:users.count})
        const userList = users;
        const newArray = [];
        const newArray1 = [];
         let  a_array = [];
         let  s_array = [];
         let  d_array = [];
         let  r_array = [];
         let  a_array1 = [];
         let  s_array1 = [];
         let  d_array1 = [];
         let  r_array1 = [];
              
       await  userList.forEach(e=>{
         if(e.source === 'Mobile'){
      
           let mom = moment.unix(e.time._seconds).format('MMMM Do YYYY, h:mm a');
           
              let stamp =  moment.unix(e.time._seconds).format('LLL');
            newArray.push({"stamp": stamp,"name":<b>{e.name}</b>,"country":e.country,"time":mom,"kyc":e.add,emails:e.email,"email":<a href={`kyc_details?uid=${e.uid}&time=${mom}&source=swn`}><span id="textSpan" style={{ fontWeight: 'bold' } }>{e.email}</span></a>,"source":<MobileFriendlyIcon />,"status":<span class={this.getKYCStatusColor(e.kyc_status)}>{KYC_STATUS_STRING[e.kyc_status]}</span>})
            newArray.sort(function(a, b) {
              var c = new Date(a.stamp);
              var d = new Date(b.stamp);
              return d-c;
          });

          } if(e.source === 'Web'){

            // console.log(e)
          var testDateUtc = moment.utc(e.time);
          var localDate = moment(testDateUtc).local();
            let mom =  JSON.stringify(localDate._d)
            mom = moment(localDate._d).format('MMMM Do YYYY, h:mm a');
            let stamp =  moment(e.time).format('LLL');

            newArray1.push({'stamp':stamp,"name":<b>{e.name}</b>,"country":e.country,"time":mom,"kyc":e.add,emails:e.email,"email":<a href={`kyc_details?uid=${e.uid}&time=${mom}&source=exchange`}><span id="textSpan" style={{ fontWeight: 'bold' } }>{e.email}</span></a>,"source":<ComputerIcon/>,"status": <span class={this.getKYCStatusColor(e.kyc_status)}>{KYC_STATUS_STRING[e.kyc_status]}</span>})
            newArray1.sort(function(a, b) {
              var c = new Date(a.stamp);
              var d = new Date(b.stamp);
              return d-c;
          });
          }
        })
        if(userList.length > 0){
          this.setState({userListLength: true})
        }
      
         let a_count = 0;
         let s_count = 0;
         let d_count = 0;
         let r_count = 0;

        await newArray.forEach(e=>{
       
         
           if(e.kyc ==='A'){
             a_count++;
             e.sl = a_count
             a_array.push(e)
           }
           if(e.kyc==='D'){
            d_count++;
            e.sl = d_count
            d_array.push(e)
          }
          if(e.kyc==='S'){
            s_count++;
            e.sl = s_count
            s_array.push(e)
          }
          if(e.kyc==='R'){
            r_count++;
            e.sl = r_count
            r_array.push(e)
          }
           
         })
         a_count = 0;
         s_count = 0;
         d_count = 0;
         r_count = 0;
         
        await newArray1.forEach(e=>{
         

          if(e.kyc==='A'){
            a_count++;
            e.sl = a_count
            a_array1.push(e)
          }
          if(e.kyc==='D'){
             d_count++;
             e.sl = d_count
             d_array1.push(e)
         }
         if(e.kyc==='S'){
            s_count++;
             e.sl = s_count
             s_array1.push(e)
         }
         if(e.kyc==='R'){
            r_count++;
            e.sl = r_count
            r_array1.push(e)
         }
          
        })

        this.setState({
          userList:newArray,
          userList1:newArray1,
          array_s : s_array,
          array_a : a_array,
          array_r : r_array,
          array_d : d_array,

          array1_s : s_array1,
          array1_a : a_array1,
          array1_r : r_array1,
          array1_d : d_array1,
          showLoader : false,
          selected_kyc_status : kyc_type
        })
      } catch (err) {
        console.log(err);
        toast.error("Error!")
      }
     }

     async componentDidMount() {
        await this.getUserList('S', this.state.network)
        const intervalTimer = setInterval(async() => {
            this.liveReload('S', this.state.network)
        }, 3000);
        this.setState({intervalTimer})
    }


    componentWillUnmount() {
      clearInterval(this.state.intervalTimer);
    }


    getKYCStatusColor = kyc_status => {
      if (kyc_status === 'A'){
          return "badge badge-success"
      }
      else if (kyc_status === 'D'){
          return "badge badge-danger"
      }
      else if (kyc_status === 'R'){
          return "badge badge-dark"
      }
      else if (kyc_status === 'S'){
          return "badge badge-primary"
      }
  }


  handleChange = () => {
        if(this.state.network === 'swn'){
          this.setState({network: 'exchange' });
          
        }
        if(this.state.network === 'exchange'){
          this.setState({network: 'swn' });
          
        }
  };
 
 handleChange_data =()=>{
   if(this.state.network === 'swn' && this.state.selected_button ==='A'){
    return this.state.array_a;
   }
   if(this.state.network === 'swn' && this.state.selected_button ==='D'){
    return this.state.array_d;
   }
   if(this.state.network === 'swn' && this.state.selected_button ==='R'){
    return this.state.array_r;
   }
   if(this.state.network === 'swn' && this.state.selected_button ==='S'){
    return this.state.array_s;
   }
 }

 handleChange1_data =()=>{
  if(this.state.network === 'exchange' && this.state.selected_button ==='A'){
   return this.state.array1_a;
  }
  if(this.state.network === 'exchange' && this.state.selected_button ==='D'){
   return this.state.array1_d;
  }
  if(this.state.network === 'exchange' && this.state.selected_button ==='R'){
   return this.state.array1_r;
  }
  if(this.state.network === 'exchange' && this.state.selected_button ==='S'){
    return this.state.array1_s;
   }
}

changeButtonstatus(data){
  if(data === 'A'){
    this.setState({A_Button:true,S_Button:false,D_Button:false,R_Button:false, selected_button:'A'})
  }else if(data === 'D'){
    this.setState({A_Button:false,S_Button:false,D_Button:true,R_Button:false, selected_button:'D'})
  }else if(data === 'S'){
    this.setState({A_Button:false,S_Button:true,D_Button:false,R_Button:false , selected_button:'S'})
  }else{
    this.setState({A_Button:false,S_Button:false,D_Button:false,R_Button:true, selected_button:'R'})

  }
}

liveReload = async(kyc_type, network)=>{
  try {
    // let counts;
    const users = await getKYCUsers(kyc_type);

    if(this.state.AcceptedCount < users.count){
      this.setState({AcceptedCount: users.count})

          const userList = users;
          const newArray = [];
          const newArray1 = [];
          let  a_array = [];
          let  s_array = [];
          let  d_array = [];
          let  r_array = [];
          let  a_array1 = [];
          let  s_array1 = [];
          let  d_array1 = [];
          let  r_array1 = [];
                
        await  userList.forEach(e=>{
          if(e.source === 'Mobile') {
            let mom = moment.unix(e.time._seconds).format('MMMM Do YYYY, h:mm a');
            let stamp =  moment.unix(e.time._seconds).format('LLL');
            newArray.push({"stamp": stamp,"name":<b>{e.name}</b>,"country":e.country,"time":mom,"kyc":e.add,emails:e.email,"email":<a href={`kyc_details?uid=${e.uid}&time=${mom}&source=swn`}><span id="textSpan" style={{ fontWeight: 'bold' } }>{e.email}</span></a>,"source":<MobileFriendlyIcon />,"status":<span class={this.getKYCStatusColor(e.kyc_status)}>{KYC_STATUS_STRING[e.kyc_status]}</span>})
            newArray.sort(function(a, b) {
              var c = new Date(a.stamp);
              var d = new Date(b.stamp);
              return d-c;
            });
          } else if(e.source === 'Web'){
                  var testDateUtc = moment.utc(e.time);
                  var localDate = moment(testDateUtc).local();
                    let mom =  JSON.stringify(localDate._d)
                    mom = moment(localDate._d).format('MMMM Do YYYY, h:mm a');
                    let stamp =  moment(e.time).format('LLL');
        
                    newArray1.push({'stamp':stamp,"name":<b>{e.name}</b>,"country":e.country,"time":mom,"kyc":e.add,emails:e.email,"email":<a href={`kyc_details?uid=${e.uid}&time=${mom}&source=exchange`}><span id="textSpan" style={{ fontWeight: 'bold' } }>{e.email}</span></a>,"source":<ComputerIcon/>,"status": <span class={this.getKYCStatusColor(e.kyc_status)}>{KYC_STATUS_STRING[e.kyc_status]}</span>})
                    newArray1.sort(function(a, b) {
                      var c = new Date(a.stamp);
                      var d = new Date(b.stamp);
                      return d-c;
            });
            }
          })
          if(userList.length > 0){
          // this.setState({userListLength: true})
          }
        
          let a_count = 0;
          let s_count = 0;
          let d_count = 0;
          let r_count = 0;

          await newArray.forEach(e=>{
        
          
            if(e.kyc ==='A'){
              a_count++;
              e.sl = a_count
              a_array.push(e)
            }
            if(e.kyc==='D'){
              d_count++;
              e.sl = d_count
              d_array.push(e)
            }
            if(e.kyc==='S'){
              s_count++;
              e.sl = s_count
              s_array.push(e)
            }
            if(e.kyc==='R'){
              r_count++;
              e.sl = r_count
              r_array.push(e)
            }
            
          })
          a_count = 0;
          s_count = 0;
          d_count = 0;
          r_count = 0;
          
          await newArray1.forEach(e=>{
          

            if(e.kyc==='A'){
              a_count++;
              e.sl = a_count
              a_array1.push(e)
            }
            if(e.kyc==='D'){
              d_count++;
              e.sl = d_count
              d_array1.push(e)
          }
          if(e.kyc==='S'){
              s_count++;
              e.sl = s_count
              s_array1.push(e)
          }
          if(e.kyc==='R'){
              r_count++;
              e.sl = r_count
              r_array1.push(e)
          }
            
          })

          this.setState({
            userList:newArray,
            userList1:newArray1,
            array_s : s_array,
            array_a : a_array,
            array_r : r_array,
            array_d : d_array,

            array1_s : s_array1,
            array1_a : a_array1,
            array1_r : r_array1,
            array1_d : d_array1,
          selected_kyc_status : kyc_type
          })
  }else{
    return;
  }
  } catch (err) {
    console.log(err);
    toast.error("Error!")
  }

}
  

    render() { 
     
      const data = {
        columns: [
        
          {
            label: '#',
            field: 'sl',
            sort: 'disabled',
            width: 150
          },
          {
            label: 'Name',
            field: 'name',
            sort: 'disabled',
            width: 150
          },
          {
            label: 'Email',
            field: 'email',
            sort: 'disabled',
            width: 150
          },
          {
            label: 'Time',
            field: 'time',
            sort: 'disabled',
            width: 150
          },
          {
            label: 'Country',
            field: 'country',
            sort: 'disabled',
            width: 150
          },
          {
            label: this.state.network==='swn'?<MobileFriendlyIcon/>:<ComputerIcon/>,
            field: 'status',
            sort: 'disabled',
            width: 270
          }
        ],
        rows: this.state.network==='swn'? this.handleChange_data() : this.handleChange1_data()
      }     
        return ( 
            <React.Fragment>
              <Container>
            <div className="container">
            
            <div className="jumbotron">
            <div className="container">
            <SideMenu />
            <br />
            <p style={{color:"red"}}>{(isDev ? "DEVELOPMENT | TESTNET" : "PRODUCTION | MAINNET")}</p>
            <h3 className="display-4">KYC Dashboard </h3>
            {this.state && this.state.showLoader && (
            <div className="col-md-6" >
                <p>Loading Data..</p>
                <Loader type="ThreeDots" color="#3f51b5" />
            </div>
            )}
            <p>
           
                <br></br>
                <button className="btn btn-info"
                        onClick={() => {
                            auth.logout();
                            window.location = "/login";
                        }}>
                        Logout
                        </button>
            </p>
         
           
            </div>
            </div>
        
            <Row>
              <Col > <p>PRODUCT SELECTION:</p>
          
          <form>
          <input type="radio" name="fruit" value="exchange" onChange={this.handleChange} defaultChecked /><b> METABLOCK EXCHANGE</b>
            <p></p>

            <input type="radio" name="fruit" value="swn" onChange={this.handleChange}  /> <b> SOVEREIGN WALLET</b>
            
          </form></Col>
            <Col sm="13" md={{ size: 6, offset: 3 }}>
            <Button.Group>
                <Button toggle active={this.state.S_Button} onClick={() => this.changeButtonstatus('S')}>SUBMITTED</Button>
                <Button.Or />
                <Button toggle active={this.state.R_Button} onClick={() => this.changeButtonstatus('R')}>REVIEW</Button>
                <Button.Or />
                <Button toggle active={this.state.D_Button} onClick={() => this.changeButtonstatus('D')}>DECLINED</Button>
                <Button.Or />
                <Button toggle active={this.state.A_Button} onClick={() => this.changeButtonstatus('A')}>ACCEPTED</Button>
            </Button.Group>
            </Col>
            </Row>
            <br /><br />
            <h6>KYC requests with status : {KYC_STATUS_STRING[this.state.selected_button]}</h6>
            <hr />
            {this.state && !this.state.showLoader &&  (
              <MDBDataTable 
                striped
                bordered
                small
                data={data}  
              />
            )}
            </div>
            </Container>
            </React.Fragment>
            );
    }
}
 
export default Home;