var AWS = require("aws-sdk");
const awsConfig = require("../config").AWS_DB_CONFIG()
const cognitoConfig = require("../config").AWS_COGNITO_CONFIG()
const {FIREBASE_URL, API_KEY_FIREBASE} = require('../config');
const { decode } =  require('html-entities');

AWS.config.update({
    accessKeyId: awsConfig.accessKeyId,
    secretAccessKey: awsConfig.secretAccessKey,
    region: awsConfig.region
});

var docClient = new AWS.DynamoDB.DocumentClient();
let cognitoISP = new AWS.CognitoIdentityServiceProvider({ region: awsConfig.region });

export  function getKYCUsers(kyc_status) {
    let myArray = [];
    return new Promise((resolve, reject) => {
        var params = {
            TableName: "user_kyc",
           
        };
        docClient.scan(params, async function(err, data) {
            if (err) {
                console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
                reject(JSON.stringify(err, null, 2))
            } else {

               
                // const options = {
                //     method: "GET",
                //     headers: { "Content-Type": "application/json", "x-api-key": API_KEY_FIREBASE },
                  
                //   };
            //     await fetch(`${FIREBASE_URL}/get_user_list`,options)
            //     .then((response) => response.json())
            //     .then(async(datas) =>{
            //         //  console.log(datas)
            //         //  console.log(data)
            //     let counts = 0
                    

            //     await datas['A'].forEach(element => {
            //         myArray.push({"name":element.profile.fname+' '+element.profile.lname,"country":element.profile.country,"time":element.profile.time,"email": element.profile.email, "kyc_status": element.kyc_state,"uid":element.uid,"source":"Mobile", "add":"A"})
            //     });
            //     await datas['R'].forEach(element => {
            //         myArray.push({"name":element.profile.fname+' '+element.profile.lname,"country":element.profile.country,"time":element.profile.time,"email": element.profile.email, "kyc_status": element.kyc_state,"uid":element.uid,"source":"Mobile", "add":"R"})
            //     });
            //     await datas['D'].forEach(element => {
            //         myArray.push({"name":element.profile.fname+' '+element.profile.lname,"country":element.profile.country,"time":element.profile.time,"email": element.profile.email, "kyc_status": element.kyc_state,"uid":element.uid,"source":"Mobile", "add":"D"})
            //     });
            //     await datas['S'].forEach(element => {
            //         counts++
            //         myArray.push({"name":element.profile.fname+' '+element.profile.lname,"country":element.profile.country,"time":element.profile.time,"email": element.profile.email, "kyc_status": element.kyc_state,"uid":element.uid,"source":"Mobile", "add":"S"})
            //     });


            //     await data["Items"].forEach(element => {
            //         element.source = 'Web'
            //         if(element.kyc_status === 'S'){
            //             counts++;
            //         }
            //         element.name = element.first_name+' '+element.last_name;
            //         element.time = element.timestamp;
            //             if(element.kyc_status){
            //                 element.add = element.kyc_status
            //                 myArray.push(element)

            //             }
                     
            //     });
            //     myArray.count = counts;
               

            //     resolve(myArray)
            //     return;
            //    // }
            //      })
              
            let counts = 0;    
            await data["Items"].forEach(element => {
                element.source = 'Web'
                if(element.kyc_status === 'S'){
                    counts++;
                }
                element.name = element.first_name+' '+element.last_name;
                element.time = element.timestamp;
                    if(element.kyc_status){
                        element.add = element.kyc_status
                        myArray.push(element)

                    }
                 
            });
            myArray.count = counts;
            console.log('my', counts)
                if(data["Items"].length){
                    resolve(myArray)
                } else{
                    resolve([])
                }
            }
        });
    });
}

export function getUserKYC(uid) {
    return new Promise((resolve, reject) => {
        var params = {
            Key:{
                "uid": uid,
            },
            TableName: 'user_kyc'
        };
        docClient.get(params, async function(err, data) {
            if (err) {
                console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
                reject(JSON.stringify(err, null, 2))
            } else {
                resolve(JSON.stringify(normalizeHtmlText(data), null, 2))
            }
        });
    });
}

// This function is required support special chars in kyc data
function normalizeHtmlText(data) {
    let normalised_data = {
        "Item": {}
    }
    for (const property in data['Item']) {
        normalised_data['Item'][property] = decode(data['Item'][property]);
    }
    return normalised_data

}
 

export function AcceptUser(uid,status,message= " "){
    return new Promise(async (resolve, reject) => {
        const options = {
            method: "GET",
            headers: { "Content-Type": "application/json" , "x-api-key": API_KEY_FIREBASE},
            //credentials: "same-origin",
              
          };
       await fetch(`${FIREBASE_URL}/update_users_list?uid=`+uid+'&state='+status+'&msg='+message,options)
        .then((response) => response.json())
        .then(async(datas) =>{
            if(datas.update === true){
                resolve(true)
                
            }
            if(datas.update === false){
                resolve(false)
                
            }
        }).catch((err)=>{
            reject(err);

        })
    });
}


export function EditProfileSwn(uid,status){
    console.log("ggg",status)
    let myArr = JSON.stringify(status)
 

    return new Promise(async (resolve, reject) => {
        const options = {
            method: "GET",
            headers: { "Content-Type": "application/json" , "x-api-key": API_KEY_FIREBASE}
              
          };
       await fetch(`${FIREBASE_URL}/update_users_profile?uid=`+uid+'&state='+myArr,options)
        .then((response) => response.json())
        .then(async(datas) =>{
            if(datas.update === true){
                resolve(true)
                
            }
            if(datas.update === false){
                resolve(false)
                
            }
        }).catch((err)=>{
            reject(err);

        })
    });
}

export async function EditProfileExchange(uid, status) {
    console.log("dataz",status)
    let extract_data ={};
    let updateQuery='set';
  
    await status.forEach(element => {
       if(element.firstName !== undefined){
           extract_data[':FNAME'] = element.firstName
           updateQuery = updateQuery+' first_name=:FNAME,'
       }
       if(element.lastName !== undefined){
         extract_data[':LNAME'] = element.lastName
         updateQuery =  updateQuery+' last_name=:LNAME,'
       }
       if(element.dob !== undefined){
          extract_data[':DOB'] = element.dob
          updateQuery =  updateQuery+' dob=:DOB,'

       }
       if(element.address !== undefined){
         extract_data[':ADDRESS'] = element.address
         updateQuery =  updateQuery+' address=:ADDRESS,'

      }
      if(element.country !== undefined){
         extract_data[':COUNTRY'] = element.country
         updateQuery =  updateQuery+' country=:COUNTRY,'

      }
      if(element.province !== undefined){
         extract_data[':PROVINCE'] = element.province
         updateQuery =  updateQuery+' province=:PROVINCE,'

      }
      if(element.doc_type !== undefined){
         extract_data[':DTYPE'] = element.doc_type
         updateQuery =  updateQuery+' document_type=:DTYPE,'

      }
      if(element.doc_no !== undefined){
         extract_data[':DNUM'] = element.doc_no
         updateQuery =  updateQuery+' document_number=:DNUM,'

      } 
      if(element.exp_date !== undefined){
         extract_data[':DEXP'] = element.exp_date
         updateQuery =  updateQuery+' document_expiry_date=:DEXP,'

      }
      if(element.zipcode !== undefined){
         extract_data[':ZIP'] = element.zipcode
         updateQuery =  updateQuery+' zip_code=:ZIP,'

      }
      if(element.city !== undefined){
         extract_data[':CITY'] = element.city
         updateQuery =  updateQuery+' city=:CITY,'

      }
      if(element.email !== undefined){
        extract_data[':EMAIL'] = element.email
        updateQuery =  updateQuery+' email=:EMAIL,'

     }
    });
 
    console.log("final",extract_data)
     updateQuery = updateQuery.substring(0, updateQuery.length - 1);
     console.log(updateQuery)


     

   
    return new Promise((resolve, reject) => {
        updateUserSecurityLevel(uid, status).then( () => {
         //   "set first_name=:FNAME, last_name=:LNAME,city=:CITY, zip_code=:ZIP,document_type=:DTYPE,document_expiry_date=:DEXP, document_number=:DNUM,province=:PROVINCE,country=:COUNTRY,address=:ADDRESS,dob=:DOB"
            var params = {
                TableName:'user_kyc',
                Key:{
                    "uid": uid
                },
                UpdateExpression: updateQuery,
                ExpressionAttributeValues:extract_data,
                ReturnValues:"UPDATED_NEW"
            };
            docClient.update(params, async function(err, data) {
                if (err) {
                    console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
                    reject(JSON.stringify(err, null, 2))
                } else {
                    resolve(JSON.stringify(data, null, 2))
                }
            });
        })
    });
}





export function verifyUser(uid, status, message=" ") {
    return new Promise((resolve, reject) => {
        updateUserSecurityLevel(uid, status).then( () => {
            var params = {
                TableName:'user_kyc',
                Key:{
                    "uid": uid
                },
                UpdateExpression: "set kyc_status=:KYC_STATUS, msg=:message",
                ExpressionAttributeValues:{
                    ":KYC_STATUS":status,
                    ":message" : message
                },
                ReturnValues:"UPDATED_NEW"
            };
            docClient.update(params, async function(err, data) {
                if (err) {
                    console.error("Unable to read item. Error JSON:", JSON.stringify(err, null, 2));
                    reject(JSON.stringify(err, null, 2))
                } else {
                    resolve(JSON.stringify(data, null, 2))
                }
            });
        })
    });
}


async function updateUserSecurityLevel(uid, status) {
    return new Promise((resolve, reject) => {
        // default sec level
        let sec_level = "2";

        // accepted kyc sec level
        if (status === 'A'){
            sec_level = "3"
        }
        
        // rejected kyc sec level
        else {
            sec_level = "2";
        }

        console.log("Updating USER SECURITY LEVEL! --> ", sec_level)

        let params = {
            UserAttributes: [
                {
                    Name: 'custom:security_level',
                    Value: sec_level
                }
            ],
            UserPoolId: cognitoConfig.userPoolId,
            Username: uid
        };

        cognitoISP.adminUpdateUserAttributes(params, async(err, data) =>{
            if(err){
            console.log(err);
            reject(err);

            }else{
                resolve(data)
            }
        })
    });
}

export async function getFetch(uid) {
    return new Promise(async(resolve, reject) => {
        const options = {
            method: "GET",
            headers: { "Content-Type": "application/json", "x-api-key": API_KEY_FIREBASE },
    
              
          };
       await fetch(`${FIREBASE_URL}/kyc_list?uid=`+uid,options)
        .then((response) => response.json())
        .then(async(datas) =>{
            if(datas.kycs === 'NO'){
                resolve('no data')
            }
            const kycs = {};
            kycs.first_name = datas.kycs.firstName;
            kycs.last_name = datas.kycs.lastName;
            kycs.zip_code = datas.kycs.zipcode;
            kycs.dob = datas.kycs.birthDate;
            kycs.city = datas.kycs.city;
            kycs.country = datas.kycs.country;
            kycs.province = datas.kycs.province;
            kycs.document_type = datas.kycs.docType;
            kycs.document_number = datas.kycs.docNumber;
            kycs.address = datas.kycs.address;
            kycs.document_expiry_date = datas.kycs.expireDate;
            kycs.email = datas.kycs.email;
            kycs.uid = datas.kycs.uid;
            kycs.kyc_status = datas.kycs.kycState;
            kycs.source = 'phone';
            kycs.selfie = datas.kycs.encodedSelfieImg;
            kycs.docimg = datas.kycs.encodedDocImg;
            kycs.msg = datas.kycs.msg;
            resolve(kycs)
        
       
        }).catch(error => {
            console.log(error);
            
        });
    });

}