import React, { useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import Loader from 'react-loader-spinner';
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button'
import SideMenu from "./SideMenu";
import { RESTORE_VC } from '../constants/api'
import { isDev } from "../config"

const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
});

const RestoreVCRequests = (props) => {
    const classes = useStyles();
    const {idToken} = props;
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [failed, setFailed] = useState(false)
    const preprocessData = (rowData, reqId = false) => {
        if (reqId) {
            return rowData.filter(data => data.req_id !== reqId)
        } else {
            return rowData.map(data => ({
                ...data,
                full_name: `${data['first_name']} ${data['last_name']}`,
            }))
        }
    }
    useEffect(() => {
        if (idToken) {
            axios.post(RESTORE_VC, {action: 'fetch_pending'}, {
                headers: {
                'Authorization': idToken
                }
            }).then(res => {
                if (res?.data?.error) {
                    setFailed(true)
                } else {
                    setData(preprocessData(res.data.data))
                    setFailed(false)
                }
            })
            .catch(_ => setFailed(true))
            .finally(_ => setLoading(false))
        }
    }, [idToken])
    const restoreVCAction = (action, req_id) => {
        setLoading(true)
        axios.post(RESTORE_VC, {
            action,
            req_id 
        }, {
            headers: {
            'Authorization': idToken
            }
        }).then(res => {
            if (res?.data?.error) {
                setFailed(true)
            } else {
                setData(preprocessData(data, req_id))
                setFailed(false)
            }
        })
        .catch(_ => setFailed(true))
        .finally(_ => setLoading(false))
    }
    return (
        <Container>
            <div className="jumbotron">
                <div className="container">
                    <SideMenu />
                    <br />
                    <p style={{color:"red"}}>{(isDev ? "DEVELOPMENT | TESTNET" : "PRODUCTION | MAINNET")}</p>
                    <h2 className="display-4">Restore VC Requests</h2>
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>DID</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Country</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {loading && <TableRow>
                        <TableCell align="left" colSpan="6">
                            <Loader type="ThreeDots" color="#3f51b5" style={{textAlign: 'center'}}/>
                        </TableCell>
                    </TableRow>}
                    {!loading && failed && <TableRow>
                        <TableCell align="left" colSpan="6">
                            Failed to fetch request list.
                        </TableCell>
                    </TableRow>}
                    {!loading && !failed && !data?.length && <TableRow>
                        <TableCell align="left" colSpan="6">
                            <p style={{textAlign: 'center'}}>No records found</p>
                        </TableCell>
                    </TableRow>}
                    {!loading && data.map((row) => (
                        <TableRow key={row.email}>
                            <TableCell align="left">{row.full_name}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.did}</TableCell>
                            <TableCell align="left">{row.create_time}</TableCell>
                            <TableCell align="left">{row.country}</TableCell>
                            <TableCell>
                                <Button
                                onClick={() => restoreVCAction('approve', row.req_id)}
                                color="primary"
                                >
                                Accept
                                </Button>
                                <Button
                                onClick={() => restoreVCAction('decline', row.req_id)}
                                color="secondary"
                                >
                                Decline
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default RestoreVCRequests

