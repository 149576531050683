import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { getUserKYC, verifyUser , AcceptUser, getFetch, EditProfileSwn, EditProfileExchange} from '../services/kycService';
import { getPresignedURL } from "../services/apiService"
import Loader from 'react-loader-spinner';
import {KYC_STATUS_STRING} from "../config"
import CircularProgress from '@material-ui/core/CircularProgress';
import Screenshot from '../services/Paper'
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import auth from "../services/loginService";
import { Button } from 'semantic-ui-react'
import swal from 'sweetalert2';
import '../Styles.css'
import TextField from '@material-ui/core/TextField';
import Dates from '../services/DatePicker'
import moment from 'moment'
import Country from '../services/Country.json'




class KycDetails extends Component {
    state = { 
        uid : "",
        kyc_details : {},
        doc_image_url : "",
        selfie_image_url : "",
        showLoader : true,
        show_rejection_msg_field : false,
        rejection_msg : "",
        no_data : false,
        data : '',
        loader_A: false,
        loader_D: false,
        tmp_state:'',
        isFull: false,
        time:'',
        editing: false,
        fname:'',
        email:'',
        lname:'',
        dob:'',
        country:'',
        province:'',
        city:'',
        address:'',
        zipcode:'',
        doc_type:'',
        doc_no:'',
        exp_date:'',
        incoming:'',
        saveLoader : false


     }

     goFull = () => {
        this.setState({ isFull: true });
      }

    verifyUser = async () => {
        this.setState({loader_A:true})
        if(this.state.kyc_details.source === 'phone' || this.state.kyc_details.source === undefined){
                await AcceptUser(this.state.uid, 'A')
                this.setState({
                    kyc_details: {
                        ...this.state.kyc_details,
                        kyc_status: 'A'
                    }
                })
                // window.location.reload(); 
                this.setState({loader_A:false})

                toast.success('KYC of App Verified!');
                return;
        }
        await verifyUser(this.state.uid, 'A');
            this.setState({
                kyc_details: {
                    ...this.state.kyc_details,
                    kyc_status: 'A'
                }
            })
            this.setState({loader_A:false})
            toast.success('KYC of Exchange Verified!');
       }

    declineUser = async () => {
        await swal
        .fire({
          title: "Rejection Message",
          text: "Enter the reason for rejection:",
          input: "text",
          showCancelButton: true,
        })
        .then(async (result) => {
          if (result.value) {
           this.setState({rejection_msg:result.value})
           this.setState({loader_D:true,})
           if(this.state.kyc_details.source === 'phone' || this.state.kyc_details.source === undefined){
   
               await AcceptUser(this.state.uid, 'D', this.state.rejection_msg)
               this.setState({
                   kyc_details: {
                       ...this.state.kyc_details,
                       kyc_status: 'D',
                       msg: this.state.rejection_msg
                   }
               })
               // window.location.reload(); 
               this.setState({loader_D:false, show_rejection_msg_field:false})
               toast.success('KYC Declined!');
              // window.location.reload(); 
               return;
   
           }
            await verifyUser(this.state.uid, 'D', this.state.rejection_msg);
            this.setState({
               kyc_details: {
                   ...this.state.kyc_details,
                   kyc_status: 'D',
                   msg: this.state.rejection_msg
               }
           })
           // window.location.reload(); 
           this.setState({loader_D:false})
            toast.success('KYC Declined!');
           // window.location.reload();
          }else{
              return;
          }
        
        });

       
        }

    getKYCStatusColor = kyc_status => {
        if (kyc_status === 'A'){
            return "badge badge-success"
        }
        else if (kyc_status === 'D'){
            return "badge badge-danger"
        }
        else if (kyc_status === 'R'){
            return "badge badge-dark"
        }
        else if (kyc_status === 'S'){
            return "badge badge-primary"
        }
    }
    
    preparePresignedUrl = path => {
        let link_path = String(path).split('.com/')[1]
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getPresignedURL(link_path)
                resolve(res['message'])
            }catch(error){
                console.log(error)
                toast.error("Error!")
                reject(error)
            }
        })
    }

    showButtons = kyc_status => {
        if (kyc_status === 'S'){
            return true
        }
        return false
    }

    async componentDidMount() {
        try {
          let search = window.location.search;
          let params = new URLSearchParams(search);
          let uid = params.get('uid');
          let time = params.get('time');
          let incoming = params.get('source');

          this.setState({uid: uid, time:time, incoming: incoming})
          let userKYCDetails = JSON.parse(await getUserKYC(uid));
            console.log("data",userKYCDetails)
          if(userKYCDetails['Item'] === undefined){
            userKYCDetails = await getFetch(uid);
            console.log("data1",userKYCDetails)

            if(userKYCDetails === 'no data'){
                this.setState({ no_data:true});
                toast.error("No Data Found in our Database")
                return;
            }
            await this.setState({kyc_details : userKYCDetails, showLoader : false, doc_image_url:userKYCDetails.docimg,selfie_image_url:userKYCDetails.selfie });
            await this.setState({fname:userKYCDetails.first_name,
                province:userKYCDetails.province,
                lname:userKYCDetails.last_name,
                dob:userKYCDetails.dob,
                country:userKYCDetails.country,
                city:userKYCDetails.city,
                address:userKYCDetails.address,
                zipcode:userKYCDetails.zip_code,
                doc_type:userKYCDetails.document_type,
                doc_no:userKYCDetails.document_number,
                exp_date:userKYCDetails.document_expiry_date,
                email:userKYCDetails.email


            })
            toast.success("Data Fetched")
            return;

          }
          userKYCDetails['Item'].source = 'Web';
          const doc_image_url = await this.preparePresignedUrl(userKYCDetails['Item']['document_image_url'])
          const selfie_image_url = await this.preparePresignedUrl(userKYCDetails['Item']['selfie_image_url'])
          this.setState({kyc_details : userKYCDetails['Item'], selfie_image_url, doc_image_url, showLoader : false})
          await this.setState({fname:userKYCDetails['Item']['first_name'],
            province:userKYCDetails['Item']['province'],
            lname:userKYCDetails['Item']['last_name'],
            dob:userKYCDetails['Item']['dob'],
            country:userKYCDetails['Item']['country'],
            city:userKYCDetails['Item']['city'],
            address:userKYCDetails['Item']['address'],
            zipcode:userKYCDetails['Item']['zip_code'],
            doc_type:userKYCDetails['Item']['document_type'],
            doc_no:userKYCDetails['Item']['document_number'],
            exp_date:userKYCDetails['Item']['document_expiry_date'],
            email:userKYCDetails['Item']['email'],


        })
          toast.success("Data Fetch Completed")
      }
      catch(error){
          toast.error("Error!")
      }
    }

     Fullon = ()=>{

        this.setState(prevState => ({isFull: !prevState.isFull}))
    }

 
    profileEdit= async()=>{
        this.setState({saveLoader:true})
      
        console.log(this.state.kyc_details.email)
        console.log(this.state.email)

        console.log(this.state.kyc_details.country)
        console.log(this.state.country)
        let myArray = [];
        let tmpArray =[];

        if(this.state.fname.length> 0 ){ 
            myArray.push({"firstName": this.state.fname })
            tmpArray.push({"value": this.state.fname, "label": 'first_name' })
        }
        else{
            
            this.sweetAlert('First Name');
            return;
            
        
        }
        if(this.state.lname.length > 0){ 
            myArray.push({"lastName": this.state.lname})
            tmpArray.push({"value": this.state.lname, 'label': 'last_name'})

        }  else{
            
            this.sweetAlert('Last Name');
            return;
            
        
        }
        if(this.state.address.length >0){ 
            myArray.push({"address": this.state.address})
            tmpArray.push({"label":"address","value": this.state.address})

        }  else{
            
            this.sweetAlert('Address');
            return;
            
        
        }
        if(this.state.city.length >0){
             myArray.push({"city": this.state.city})
             tmpArray.push({"label":"city","value": this.state.city})

            }  else{
            
                this.sweetAlert('City');
                return;
                
            
            }

        if(this.state.country.length > 0){
             myArray.push({"country": this.state.country})
             tmpArray.push({"label":"country","value": this.state.country})

            }  else{
            
                this.sweetAlert('Country');
                return;
                
            
            }
        if(this.state.dob.length > 0){
             myArray.push({"dob": this.state.dob})
             tmpArray.push({"label":"dob","value": this.state.dob})

            }  else{
            
                this.sweetAlert('DOB');
                return;
                
            
            }
        if(this.state.province.length > 0){
             myArray.push({"province": this.state.province})
             tmpArray.push({"label":"province", "value": this.state.province})

            }else{
                
                this.sweetAlert('Province');
                return;
                
            
            }

        if(this.state.zipcode.length > 0){
             myArray.push({"zipcode": this.state.zipcode})
             tmpArray.push({"label":"zip_code", "value": this.state.zipcode})

            }  else{
            
                this.sweetAlert('Zipcode');
                return;
                
            
            }
        if(this.state.doc_type.length > 0){ 
            myArray.push({"doc_type": this.state.doc_type})
            tmpArray.push({"label":"document_type","value": this.state.doc_type})

        }  else{
            
            this.sweetAlert('Doc type');
            return;
            
        
        }
        if(this.state.doc_no.length > 0){
             myArray.push({"doc_no": this.state.doc_no})
             tmpArray.push({"label":"document_number", "value": this.state.doc_no})

            }  else{
            
                this.sweetAlert('Doc Number');
                return;
                
            
            }
        if(this.state.exp_date.length > 0){
             myArray.push({"exp_date": this.state.exp_date})
             tmpArray.push({"label":"document_expiry_date", "value": this.state.exp_date})

            }  else{
            
                this.sweetAlert('Exp date');
                return;
                
            
            }

            if(this.state.email.length > 0){
                myArray.push({"email": this.state.email})
                tmpArray.push({"label":"email", "value": this.state.email})
   
               }  else{
               
                   this.sweetAlert('Email');
                   return;
                   
               
               }


        if(
           this.state.fname.length > 0  ||
            this.state.lname.length > 0 ||
            this.state.address.length > 0  ||
            this.state.city.length > 0  ||
            this.state.country.length > 0  ||
            this.state.dob.length > 0  ||
            this.state.province.length > 0  ||
            this.state.zipcode.length > 0 ||
            this.state.doc_type.length > 0  ||
            this.state.doc_no.length > 0 ||
            this.state.email.length > 0){

                await tmpArray.map(async e=>{
                    console.log(JSON.stringify(e))
                    let strs = e.label
                    let dat = e.value
                  
                    await this.setState(prevState =>({ kyc_details:{...prevState.kyc_details,[strs]:dat}}));

                })

                if(this.state.incoming==='swn'){

                    await EditProfileSwn(this.state.uid, myArray)
                    swal.fire(
                        'Saved!',
                        'You Data Has Been Updated!',
                        'success'
                      ).then(async (result) => {
                        this.setState({editing:false, saveLoader:false});
                      })

                }
                if(this.state.incoming==='exchange'){
                 

                        await EditProfileExchange(this.state.uid, myArray)
                        swal.fire(
                            'Saved!',
                            'You Data Has Been Updated!',
                            'success'
                          ).then(async (result) => {
                            this.setState({editing:false, saveLoader:false});
                            
        
                          })
                       

                }
             
            }
            else{
              
                swal.fire(
                    'Warning!',
                    'Empty Field or No Change Detected!',
                    'warning'
                  ).then(async (result) => {
                    this.setState({editing:false,saveLoader:false});

                  })
               
            }
  

    }

    sweetAlert = (data)=>{
        swal.fire(
            'Warning!',
            'Empty '+data+ ' Field Detected!',
            'warning'
          ).then(async (result) => {
            this.setState({saveLoader:false});

          })

    }

    
    callbackFunction = (childData, label) => {
        const dformat = moment(childData).format("YYYY/MM/DD");
        console.log("lab",label)
        if(label === 'Date of Birth'){
            this.setState({ dob: dformat });
        }
        if(label === 'Expiry Date'){
            this.setState({ exp_date: dformat });
        }
      };


    render() { 
        const { kyc_details } = this.state;
       
        const docs = [
            {
              value: 'Passport',
              label: 'Passport',
            },
            {
              value: 'Government ID',
              label: 'Government ID',
            }
          ];

          
    
        return ( <React.Fragment>
            <br /><br />
           
            <div className="jumbotron">
            <div className="container">
            <h2 className="display-4">Kyc Admin Tool </h2>
          

            {this.state && this.state.showLoader && (
            <div className="col-md-3">
                <p>Loading Data..</p>
                <Loader type="ThreeDots" color="#3f51b5" />
            </div>
            )}
            <p>
                <button className="btn btn-info"
                        onClick={() => {
                            this.props.history.push("/");


                        }}>
                        Back 
                        </button>
            </p>
            <button className="btn btn-info"
                        onClick={() => {
                            auth.logout();
                            window.location = "/login";
                      


                        }}>
                        Logout
                        </button>
            </div>
           
            </div>
            {this.state.no_data === true &&<div>
                <div className="row">
                    <div className="col-md-2">
                        <button  type="button" className="btn btn-warning" onClick={this.verifyUser}>Approve {this.state.loader_A&& <CircularProgress size={20}
        thickness={4}/>} </button>

                      

                    </div>
                    <div className="col-md-4">
                        <button type="button" className="btn btn-primary" onClick={this.declineUser}>Reject{this.state.loader_D&& <CircularProgress size={20}
        thickness={4}/>}</button>
                       
                    </div>
                    <div className="col-md-6">
                    KYC Status : <span class={this.getKYCStatusColor('R')}>NO DATA FOUND.</span>
                        
                    </div>

                </div> 

               
            
            </div>}

            {this.state && !this.state.showLoader && !this.state.editing && (
                <div>
            <Paper elevation={3} >
            <div className="container">

                
                <div className="row">
                   
                   <Button.Group size='large' style={{width: '450px'}}>
                            <Button positive loading={this.state.loader_A} disabled={kyc_details.kyc_status==='A'} onClick={this.verifyUser}> <i className="thumbs up outline icon"></i>Accept User</Button>
                            <Button.Or />
                            <Button negative loading={this.state.loader_D} disabled={kyc_details.kyc_status==='D'} onClick={this.declineUser}><i className="trash alternate icon"></i>Reject User</Button>
                            <Button.Or />
                            <Button onClick={()=>this.setState({editing:true})}><i className="pencil alternate icon"></i>Edit Profile</Button>
                    </Button.Group>
                   
    
                </div> 
                
            
        
                <div className="row">
                       
                    <div className="col-md-6">
                        <br></br>
                        <div class='basic-details'>
                            <div class='header'><b>Basic Details : </b></div>
                        <p>
                        <hr />
                        Email : <strong style={{'text-transform': 'lowercase'}}>{kyc_details.email}</strong>
                    <br/><br></br>
                          
                            First Name : <strong>{kyc_details.first_name}</strong>
                            <br/>
                            Last Name : <strong>{kyc_details.last_name}</strong>
                            <br/><br/>
                            DOB : <strong>{String(kyc_details.dob)}</strong>

                         
                        </p>
                        </div>
                        <hr />
                        <div class='basic-details'>
                        <p><b><div class='header'>Address : </div></b>
                        <hr />
                            Country : <strong>{kyc_details.country}</strong>
                            <br/>
                            Province : <strong>{kyc_details.province}</strong>
                            <br/>
                            City : <strong>{kyc_details.city}</strong>
                            <br/>
                            Address : <strong>{kyc_details.address}</strong>
                            <br/>
                            Zipcode : <strong>{kyc_details.zip_code}</strong>
                            <hr />
                        </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <br></br>
                        <div class='basic-details'>
                        <p><b><div class='header'>KYC DETAILS : </div></b>

                        <hr />
                            
                        KYC Status : <span class={this.getKYCStatusColor(kyc_details.kyc_status)}>{KYC_STATUS_STRING[kyc_details.kyc_status]}</span>

                            <br></br><br></br>
                            kYC Submission Date : <strong>{this.state.time}</strong>
                                 <br></br><br></br>
                                 <div  style={{margin: 'auto', color: 'red'}}>{kyc_details.kyc_status==='D' &&(<div><b>Rejection Note: {kyc_details.msg}</b></div>)}</div>  
                                 <div  style={{margin: 'auto', color: 'green'}}>{kyc_details.kyc_status==='A' &&(<div><b>User Status: Active</b></div>)}</div>  
   
                                 <br></br>
                          
                            <hr />
                        </p>
                        </div>
                       
                       
                        <div class='basic-details'>

                        <p><b><div class='header'>Document Details : </div></b>
                        
                        <hr />
                            
                            Doc Type : <strong>{kyc_details.document_type}</strong>
                            <br/>
                            Doc Number : <strong>{kyc_details.document_number}</strong>
                            <br/>
                            Expiry Date : <strong>{String(kyc_details.document_expiry_date)}</strong>
                            <br/><br/>
                            UID : <strong>{kyc_details.uid}</strong>
                            <br/>
                            <hr />

                        </p>
                        </div>
                      
                    </div>
                    
                </div> 
             
                <div className="row">
                    <div className="col-md-6">
                        
                    <b><div class='header'>DOCUMENT DETAILS : </div></b>
                    <br></br>

                        <Paper elevation={5} style={{width:'320px',height:'180px'}} onClick={this.Fullon}>
                   
                            <Screenshot data={this.state.doc_image_url} act={this.state.isFull}/>
                        {/* <img src={this.state.doc_image_url} className="rounded float-left" alt="kyc doc" width="350" height="200" onClick={this.goFull}/>
                       */}
                      
                         </Paper>
                       
                         <Tooltip title="Click To Download" arrow>
                         <a href={this.state.doc_image_url} target={"_blank"} rel="noopener noreferrer" download>  <button className="ui button active" style={{width: '320px'}}><i className="download icon"></i></button></a>
                         </Tooltip>
                    </div>
                    
                    <div className="col-md-6">
                        
                    <b><div class='header'>SELFIE : </div></b>
                    <br></br>
                        <Paper elevation={5} style={{width:'320px',height:'180px'}} onClick={this.Fullon}>
                            
                        <Screenshot  data={this.state.selfie_image_url} act={this.state.isFull}/>

                        {/* <img src={this.state.selfie_image_url} className="rounded float-left" alt="selfie doc" width="350" height="200"  />
                      */}
                        </Paper>
                        <Tooltip title="Click To Download" arrow>
                        <a href={this.state.selfie_image_url} target={"_blank"} rel="noopener noreferrer" download>  <button className="ui button active" style={{width: '320px'}}><i className="download icon"></i></button></a>
                        </Tooltip>


                    </div>
                     
                </div>
                <div></div>
            </div> 
            </Paper></div>   )}

        

            {this.state && !this.state.showLoader && this.state.editing && (
                <div>
            <Paper elevation={3} >
            <div className="container">

                <div className="row">
                   
                   <Button.Group size='large' style={{width: '350px'}}>
                            <Button positive loading={this.state.saveLoader} onClick={()=>this.profileEdit()} ><i className="thumbs up outline icon"></i>Save</Button>
                            <Button.Or />
                            <Button negative onClick={()=>this.setState({editing:false})}><i className="sign-out alternate icon"></i>Cancel</Button>
                    </Button.Group>
                   
    
                </div> 
                
                <div className="row">
                    
                       
                    <div className="col-md-6">
                        <br></br>
                        <div class='basic-details'>
                            <div class='header'><b>Basic Details : </b></div>
                        <p>
                        <hr />
                        <TextField
                            id="email"
                            label="Email"
                            defaultValue={kyc_details.email}
                            variant="outlined"
                            onChange={(e)=>this.setState({email:e.target.value})}
                            />
                            <br></br><br></br>
                          
                            <TextField
                            id="fname"
                            label="First Name"
                            defaultValue={kyc_details.first_name}
                            variant="outlined"
                            onChange={(e)=>this.setState({fname:e.target.value})}
                            />
                            <br></br><br></br>
                            <TextField
                            id="lname"
                            label="last Name"
                            defaultValue={kyc_details.last_name}
                            variant="outlined"
                            onChange={(e)=>this.setState({lname:e.target.value})}
                            />
                            <br></br><br></br>
                          
                              {/* <TextField
                               disabled
                                id="outlined-helperText"
                                label="uid"
                                defaultValue={kyc_details.uid}
                                variant="outlined"
                                />
                                 <br></br><br></br> */}
                                <div id='container2'><Dates parentCallback={this.callbackFunction} defaultVal={kyc_details.dob} label="Date of Birth"/></div>
                         
                                <br></br><br></br> <br></br><br></br>
                         
                        </p>
                        </div>
                        <hr />
                        <div class='basic-details'>

                    <p><b><div class='header'>Document Details : </div></b>

                    <hr />
                        
                    <TextField style={{width:'205px'}}
                            id="doctype"
                            select
                            label="Document type"
                            defaultValue={kyc_details.document_type}
                            onChange={(e)=>this.setState({doc_type:e.target.value})}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            >
                            {docs.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))}
                            </TextField>
                        <br></br><br></br>
                        <TextField
                        id="docno"
                        label="Document number"
                        defaultValue={kyc_details.document_number}
                        variant="outlined"
                        onChange={(e)=>this.setState({doc_no:e.target.value})}
                        />
                        <br></br><br></br>
                    <div id='container2'><Dates parentCallback={this.callbackFunction} defaultVal={kyc_details.document_expiry_date} label="Expiry Date"/></div>

                      
                       
                    </p>
                    </div>
                    </div>
                    <div className="col-md-6">
                        <br></br>
                        <div class='basic-details'>
                        <p><b><div class='header'>ADDRESS : </div></b>

                        <hr />
                            
                        <TextField style={{width:'205px'}}
                            id="doctypes"
                            select
                            label="Country"
                            defaultValue={kyc_details.country}
                            onChange={(e)=>this.setState({country:e.target.value})}
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            >
                            {Country.map((option) => (
                                <option key={(kyc_details.country.length===2)?option.code:option.name} value={(kyc_details.country.length===2)?option.code:option.name}>
                                {(kyc_details.country.length===2)?option.name:option.name}
                                </option>
                            ))}
                            </TextField>
                      
                           <br></br><br></br>
                            <TextField
                            id="province"
                            label="province"
                            defaultValue={kyc_details.province}
                            variant="outlined"
                            onChange={(e)=>this.setState({province:e.target.value})}
                            />
                             <br></br><br></br>
                            <TextField
                            id="city"
                            label="city"
                            defaultValue={kyc_details.city}
                            variant="outlined"
                            onChange={(e)=>this.setState({city:e.target.value})}
                            />
                             <br></br><br></br>
                            <TextField
                            id="address"
                            label="address"
                            defaultValue={kyc_details.address}
                            variant="outlined"
                            onChange={(e)=>this.setState({address:e.target.value})}
                            />
                             <br></br><br></br>
                            <TextField
                            id="zipcode"
                            label="zip code"
                            defaultValue={kyc_details.zip_code}
                            variant="outlined"
                            onChange={(e)=>this.setState({zipcode:e.target.value})}
                            />
                             <br></br><br />

                            <hr />
                        </p>
                        </div>

                        <div class='basic-details'>
                        <p><b><div class='header'>KYC Details : </div></b>

                        <hr />
                            
                        KYC Status : <span class={this.getKYCStatusColor(kyc_details.kyc_status)}>{KYC_STATUS_STRING[kyc_details.kyc_status]}</span>

                        <br></br><br></br>
                        kYC Submission Date : <strong>{this.state.time}</strong>
                            <br></br><br></br>
                            <div  style={{margin: 'auto', color: 'red'}}>{kyc_details.kyc_status==='D' &&(<div><b>Rejection Note: {kyc_details.msg}</b></div>)}</div>  
                            <div  style={{margin: 'auto', color: 'green'}}>{kyc_details.kyc_status==='A' &&(<div><b>User Status: Active</b></div>)}</div>  

                            <br></br>
                                                
                                                
                        </p>
                        </div>
                       
                       
                    
                      
                    </div>
                    
                </div> 
             
                <div className="row">
                    <div className="col-md-6">
                        
                    <b><div class='header'>DOCUMENT DETAILS : </div></b>
                    <br></br>

                        <Paper elevation={5} style={{width:'320px',height:'180px'}} onClick={this.Fullon}>
                   
                            <Screenshot data={this.state.doc_image_url} act={this.state.isFull}/>
                        {/* <img src={this.state.doc_image_url} className="rounded float-left" alt="kyc doc" width="350" height="200" onClick={this.goFull}/>
                       */}
                      
                         </Paper>
                       
                         <Tooltip title="Click To Download" arrow>
                         <a href={this.state.doc_image_url} target={"_blank"} rel="noopener noreferrer" download>  <button className="ui button active" style={{width: '320px'}}><i className="download icon"></i></button></a>
                         </Tooltip>
                    </div>
                    
                    <div className="col-md-6">
                        
                    <b><div class='header'>SELFIE : </div></b>
                    <br></br>
                        <Paper elevation={5} style={{width:'320px',height:'180px'}} onClick={this.Fullon}>
                            
                        <Screenshot  data={this.state.selfie_image_url} act={this.state.isFull}/>

                        {/* <img src={this.state.selfie_image_url} className="rounded float-left" alt="selfie doc" width="350" height="200"  />
                      */}
                        </Paper>
                        <Tooltip title="Click To Download" arrow>
                        <a href={this.state.selfie_image_url} target={"_blank"} rel="noopener noreferrer" download>  <button className="ui button active" style={{width: '320px'}}><i className="download icon"></i></button></a>
                        </Tooltip>


                    </div>
                     
                </div>
                <div></div>
            </div> 
            </Paper></div>   )}




        </React.Fragment> );
    }
}
 
export default KycDetails;