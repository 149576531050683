import 'date-fns';
import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import moment from 'moment'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


class DatePicker extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedDate: ''
    }
  }

  componentDidMount(){
    console.log("sam ate", this.props.defaultVal)
    if(this.props.defaultVal === 'No Expiration Date'){
      this.setState({selectedDate: moment().format('YYYYMMDD')})

    }else{
      this.setState({selectedDate: this.props.defaultVal})

    }
  }

  async handleDateChange(dates, label){
    const dformat = moment(dates).format('YYYY/MM/DD')
    await this.setState({selectedDate:dformat})
    this.props.parentCallback(dformat,this.props.label);
  }

  render(){
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} >
        <Grid container justify="space-around">
          <KeyboardDatePicker
           label={this.props.label}
            margin="normal"
            id="date-picker-dialog"
            format="YYYY/MM/DD"
            value={this.state.selectedDate}
            onChange={(e,label)=>this.handleDateChange(e,label)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </MuiPickersUtilsProvider>
    );
  }
}

export default DatePicker;
