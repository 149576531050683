/*
Switch between production and test environment by modifying this flag
set isDev = true for test environment
set isDev = false to prod environment
IMP : Logout before changing the environment
*/
let isDev = process.env.REACT_APP_isDev === 'true';


const AWS_COGNITO_CONFIG = _=> (isDev ? require(process.env.REACT_APP_AWS_COGNITO_CONFIG_DEV) : require(process.env.REACT_APP_AWS_COGNITO_CONFIG_PROD));

const AWS_DB_CONFIG = _=> (isDev ? require(process.env.REACT_APP_AWS_DB_CONFIG_DEV) : require(process.env.REACT_APP_AWS_DB_CONFIG_PROD));


const PRESIGNED_API_URL = isDev ? process.env.REACT_APP_PRESIGNED_API_URL_DEV : process.env.REACT_APP_PRESIGNED_API_URL_PROD

const SUPPORT_TICKET_BUCKET_NAME =  isDev ? process.env.REACT_APP_SUPPORT_TICKET_BUCKET_NAME_DEV : process.env.REACT_APP_SUPPORT_TICKET_BUCKET_NAME_PROD;
const KYC_BUCKET_NAME =  isDev ? process.env.REACT_APP_KYC_BUCKET_NAME_DEV : process.env.REACT_APP_KYC_BUCKET_NAME_PROD; 



const PERMITTED_USER_GROUP = process.env.REACT_APP_PERMITTED_USER_GROUP

const GOOGLE_SITE_KEY = isDev ? process.env.REACT_APP_GOOGLE_SITE_KEY_DEV : process.env.REACT_APP_GOOGLE_SITE_KEY_PROD

//Api key and url for firebase

 const API_KEY_FIREBASE = process.env.REACT_APP_API_KEY_FIREBASE;
 const FIREBASE_URL =  isDev ? process.env.REACT_APP_FIREBASE_URL_DEV : process.env.REACT_APP_FIREBASE_URL_PROD


 const KYC_STATUS_STRING = {
    'A' : "Approved",
    'D' : "Declined",
    'R' : "In Review",
    'S' : "Submitted"
}

const SUPPORT_TICKET_STATUS = {
    'open' : "Opened",
    'close' : "Closed",
    'review' : "In Review"
}


module.exports = {
    isDev,
    AWS_DB_CONFIG,
    AWS_COGNITO_CONFIG,
    GOOGLE_SITE_KEY,
    KYC_STATUS_STRING,
    PRESIGNED_API_URL,
    PERMITTED_USER_GROUP,
    SUPPORT_TICKET_BUCKET_NAME,
    KYC_BUCKET_NAME,
    SUPPORT_TICKET_STATUS,
    API_KEY_FIREBASE,
    FIREBASE_URL
}