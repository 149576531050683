import React, { Component } from 'react';
import { isDev } from "../config"
import SideMenu from "./SideMenu";
import { toast } from "react-toastify";
import { createNotice, updateBody } from '../services/noticeService';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

class CreatePromotions extends Component {
    state = { 
        title : "",
        summary : "",
        body_en : "",
        body_ru : "",
        body_ja : "",
        body_ko : "",
        title_count : 50,
        title_count1 : 70
     }

     handleFormSubmit = e => {
        e.preventDefault();
        this.doSubmit();
      };
  
      doSubmit = async(e) => {
        try {
          const body = this.combine_and_create_notice_body();
          if(this.props.location.state !== undefined){
            await updateBody(this.props.location.state.notice.notice_id,this.state.title, this.state.summary, body)
            toast.success("Promo Updated!")
            this.props.history.push("/notices", 'promo');
            return;
          }
          await createNotice(this.state.title, this.state.summary, body, 'homepage')
          //window.location.reload()
          toast.success("Promo created!")
          this.props.history.push("/notices", 'promo');

        } catch (err) {
          console.log(err);
          toast.warning('Add failed');
        }
      }
  
      combine_and_create_notice_body = () => {
        return "<<en>>" + String(this.state.body_en) + "<<ru>>" + String(this.state.body_ru) + "<<ko>>" + String(this.state.body_ko) + "<<ja>>" + String(this.state.body_ja)
      }

      async componentDidMount(){
        if(this.props.location.state !== undefined){
         var en =  this.props.location.state.notice.body.split('<<en>>')[1].split('<<ru>>')[0]
         var ru = this.props.location.state.notice.body.split('<<ru>>')[1].split('<<ko>>')[0]
         var ko =  this.props.location.state.notice.body.split('<<ko>>')[1].split('<<ja>>')[0]
         var ja = this.props.location.state.notice.body.substring(this.props.location.state.notice.body.indexOf('<<ja>>') + 6);
          await this.setState({title:this.props.location.state.notice.title,
            summary: this.props.location.state.notice.summary,
            body_en: en,
            body_ja: ja,
            body_ru: ru,
            body_ko: ko,
            
          })

        }
      }

      changeCount =(values)=>{

        this.setState({ title: values,title_count: 50-values.length})
    
    }

    changeCount1 =(values)=>{

      this.setState({ summary: values,title_count1: 70-values.length})
  
  }

    render() { 
      return ( 
        <React.Fragment>
            <div className="jumbotron">
            <div className="container">
            <SideMenu />
            <br />
            <p style={{color:"red"}}>{(isDev ? "DEVELOPMENT | TESTNET" : "PRODUCTION | MAINNET")}</p>
            <h2 className="display-4">Create Promotion </h2>
            <p>
                <button className="btn btn-info"
                        onClick={() => {
                          this.props.history.push("/notices", 'promo');

                        }}>
                        Back
                        </button>
            </p>
            </div>
            </div>
            <br></br>
            <Paper elevation={3} >
            <form onSubmit={this.handleFormSubmit} style={{'margin':'15px'}}>
            <Grid container spacing={3}>   
            <Grid item xs={6} sm={4}>        
          
            <TextField
                style={{'width': '340px'}}
              id="outlined"
              label="TITLE"
              placeholder="Enter the title"
              multiline
              variant="outlined"
              defaultValue={this.state.title}
              // onChange={e => this.setState({ title: e.target.value })}
              onChange={e => this.changeCount(e.target.value)}

              inputProps={{maxLength :50}}
              required
               helperText={"Remaining Charaters: "+this.state.title_count}



            // fullWidth
            
            />
            </Grid>
            <Grid item xs={6} sm={4}>
            <TextField
                      id="outlined"
                      style={{'width': '710px'}}
                      label="SUMMARY"
                      placeholder="Enter the summary"
                      multiline
                      variant="outlined"
                      defaultValue={this.state.summary}
                      onChange={e => this.changeCount1(e.target.value)}

                      inputProps={{maxLength :70}}
                      required
                      helperText={"Remaining Charaters: "+this.state.title_count1}

                  
                    />
            </Grid>
            </Grid>
            <br></br>
            <small className="text-muted">Note : Use &lt;br/&gt; for new line</small>
            <br /><br></br>

            <TextField
                      id="outlined"
                      label="ENGLISH CONTENT"
                      placeholder="Enter the English Content"
                      multiline
                      rowsMax="10"
                      variant="outlined"
                      defaultValue={this.state.body_en}
                      onChange={e => this.setState({ body_en: e.target.value })}
                      fullWidth
                    />
                    <br></br><br></br>

                  <TextField
                      id="outlined"
                      label="KOREAN CONTENT"
                      placeholder="Enter the Korean Content"
                      multiline
                      rowsMax="10"
                      variant="outlined"
                      defaultValue={this.state.body_ko}
                      onChange={e => this.setState({ body_ko: e.target.value })}
                      fullWidth
                    />

                    <br></br><br></br>

                    <TextField
                        id="outlined"
                        label="RUSSIAN CONTENT"
                        placeholder="Enter the Russian Content"
                        multiline
                        rowsMax="10"
                        variant="outlined"
                        defaultValue={this.state.body_ru}
                        onChange={e => this.setState({ body_ru: e.target.value })}
                        fullWidth
                      />


                      <br></br><br></br>

                      <TextField
                          id="outlined"
                          label="JAPANESE CONTENT"
                          placeholder="Enter the Japanese Content"
                          multiline
                          rowsMax="10"
                          variant="outlined"
                          defaultValue={this.state.body_ja}
                          onChange={e => this.setState({ body_ja: e.target.value })}
                          fullWidth
                        />
            <br /><br></br>
              
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <br></br><br></br>

            </form>
            </Paper>
            <br></br><br></br>

            </React.Fragment>
            );
    }
}
 
export default CreatePromotions;
