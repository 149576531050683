import {PRESIGNED_API_URL, KYC_BUCKET_NAME} from "../config"
import {getUserToken} from "./loginService.js"

export function getPresignedURL(path, bucket=KYC_BUCKET_NAME){
    const URL = PRESIGNED_API_URL + '?path=' + path + '&bucket=' + bucket;
    return new Promise(async (resolve, reject) => {
        try{
        const authToken = await getUserToken();
        const token = authToken.idToken.jwtToken;
        //console.log(token)
        const response = await fetch(URL,
          {
            method : "GET",
            headers : new Headers({
              'Authorization' : token
            })
          });
        console.log(response)
        if (response.ok) {
            let responseJson = await response.json()
            console.log(responseJson)
            resolve(responseJson);
          } else {
            console.log("error")
            console.log(response);
            reject(false);
          }  } 
        catch(error){
          console.log(error);
          reject(error)
        }    
    })   
}