import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Image from 'react-image-resizer';


class Screenshot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: "",
      isLoading: true,
      isFull: false,
  
    };
  }
  goFull = () => {
    this.setState({ isFull: true });
  }

 

  render() {
    const { data } = this.props;
    let res;

    if (!data) {
      res = (
        <div>
          <CircularProgress disableShrink />
        </div>
      );
    } else {
      res = (
        <div>
       
       <Image
          src={data}
          width={320}
          height={180}
        />
        </div>
      );
    }

    return <div>{res}</div>;
  }
}

export default Screenshot;
